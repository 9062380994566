import React, { useEffect, useState } from "react";
import "../../App.css";
import { useForm } from "react-hook-form";
import "./Contribute.css";
import _ from "lodash/fp";
import Modal from '@material-ui/core/Modal';
import {REACT_BACKEND_SERVER} from '../../app-config';
import GiftPayModal from "../GiftPayModal/GiftPayModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function handleChangePledge(data, userId, registryItem, setUpdatingData, pledgeId) {


  fetch(REACT_BACKEND_SERVER + "/registry-pledge/"+pledgeId+"/", {
    method: "PUT",
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      registry_item: registryItem.id,
      account: userId,
      amount_pledged: data.amount,
      message: data.message,
      amount_payed: 0.0,
      id: pledgeId
    })
  })
  .then((res) => res.json())
  .then((json) => {     
    setUpdatingData(true)
  });

}

function handleNewPledge(data, userId, registryItem, setUpdatingData) {
  fetch(REACT_BACKEND_SERVER + "/registry-pledge/", {
    method: "POST",
    headers: {
      Authorization: `JWT ${localStorage.getItem("token")}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      registry_item: registryItem.id,
      account: userId,
      amount_pledged: data.amount,
      message: data.message,
      amount_payed: 0.0,
    })
  })
  .then((res) => res.json())
  .then((json) => {     
    setUpdatingData(true)
  });
}



function Contribute(props) {

  const [defaultValue, setDefaultValue] = useState({})
  const [PayModalShow, setPayModalShow] = useState(false);
  const [openPayModal, setopenPayModal] = useState(undefined);

  useEffect(() => {
    const tempDefaultValue = defaultValue
    if (props.pledgeData) {
      tempDefaultValue["amount"] = props.pledgeData.amountPledged;
      tempDefaultValue["message"] = props.pledgeData.message;
    }
    setDefaultValue(tempDefaultValue)
    reset(tempDefaultValue)


  }, [props.pledgeData])



  const { register, handleSubmit, errors, reset } = useForm({defaultValue});

  const onSubmit = (data) => {
    if (props.pledgeData) {
      handleChangePledge(data, props.userId, props.data, props.setUpdatingData, props.pledgeData.id);
    } else {
      handleNewPledge(data, props.userId, props.data, props.setUpdatingData);
    }
    // window.open("https://revolut.me/danemilyswedding", "_blank")
      setPayModalShow(true);
    props.onHide()
  };
  return (
    <>
    <Modal open={props.show && props.openModal === props.data.id} onClose={props.onHide}>
      <div className={"formBorder"}>
        <form onSubmit={handleSubmit(onSubmit)} className={"contributeForm"}>
          <a className={"close-icon"} onClick={() => props.onHide()}><FontAwesomeIcon icon={faTimes} size="2x"/></a>
          <h1 className={"modalHeader"}>{props.data.name}</h1>
          {props.data.name != "Day Trips" &&
          <p>Amount left to raise: £{Math.round((props.data.amount_required - props.amountPledged) * 100)/100}</p>}
          <p>I would like to pledge:</p>
          <div className="input-icon">
          <span className="prefix">
          £
          </span>
          <input
            className={"amount-input"}
            name="amount"
            type="number"
            step=".01"
            ref={register({
              required: "Required",
              max: props.data.amount_required - props.amountPledged + defaultValue.amount,
              min: 0
            })}
          />
          </div>
          <p>Message for the Bride and Groom...</p>
          <textarea 
            className={"pledgeTextBox"}
            name="message"
            type = "text"
            rows="4" 
            cols="50"
            ref={register()}
          />
          {_.get("amount.type", errors) === "required" && (
            <p className={"error"}>This field is required</p>
          )}
          {_.get("amount.type", errors) === "max" && (
            <p className={"error"}>Contributions must be less than or equal to amount remaining</p>
          )}
          {_.get("amount.type", errors) === "min" && (
            <p className={"error"}>Contributions must not be negative</p>
          )}
          <input type="submit"/>
        </form>
      </div>
    </Modal>
    <GiftPayModal show={PayModalShow} onHide ={()=> setPayModalShow(false)}/>
    </>
  );
}
export default Contribute;