import React from "react";
import barn from "../../Assets/barn.jpg";
import "./Dinner.css";
import { faMapMarkerAlt, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Dinner(props) {
  return (
    <div className={"dinner-section"}>
      <img src={barn} className={"barn-image"} width="350" alt={"barn"} />
      <div className={"dinner-content"}>
        <div className="dinner-when-where">
          <p className="dinner-when-where-text"> When and Where</p>
          <div className={"dinner-where"}>
            <p className={"where-icon"}>
              <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
            </p>

            <p className="dinner-where-text">
              The Kingscote Barn, Binley Farm, Kingscote, Tetbury GL8 8YE
            </p>
          </div>
          <div className={"dinner-when"}>
          {props.loggedIn && <><p className={"when-icon"}>
              <FontAwesomeIcon icon={faClock} size="lg" />
            </p>

             <p className="dinner-when-text">14:30 - 19:30, August 15, 2022</p></>}
          </div>
        </div>
      </div>
    </div>
  );
}
