import React, { useState, useEffect } from "react";
import "./App.css";
import Navbar from "./Components/NavBar/Navbar";
import HomePage from "./Components/HomePage/HomePage";
import LogIn from "./Components/LogIn/LogIn";
import Registry from "./Components/Registry/Registry";
import Profile from "./Components/Profile/Profile";
import {REACT_BACKEND_SERVER} from './app-config';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import LoginModal from "./Components/LoginModal/LoginModal";
import NavBarMobile from "./Components/NavBarMobile/NavBarMobile";
import ImageLibrary from "./Components/ImageLibrary/ImageLibrary";

function App() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [navBackground, setNavBackground] = useState("rgba(0,0,0,0.0)");
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") ? true : false
  );
  const [username, setUsername] = useState("");
  const [updatingData, setUpdatingData] = useState(false)
  const [userId, setUserId] = useState(null);
  const [personData, setPersonData] = useState([]);
  const [mealChoiceData, setMealChoiceData] = useState([]);
  const [mealChoiceAllergenData, setMealChoiceAllergenData] = useState([]);
  const [allergenData, setallergeneData] = useState([]);
  const [hotelData, setHotelData] = useState([]);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loginError, setLoginError] = useState(undefined);
  const [registryItemData, setRegistryItemData] = useState([]);
  const [registryPledgeData, setRegistryPledgeData] = useState([]);

  const handleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  const handle_login = (data) => {
    var loginErrorTemp = undefined;
    fetch(REACT_BACKEND_SERVER + "/token-auth/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status !== 200) {
          loginErrorTemp=res.status
        }
          
        return res.json()
        })
      .then((json) => {
        if(loginErrorTemp === undefined) {
          localStorage.setItem("token", json.token);
          setLoggedIn(true);
          setUsername(json.account.username);
          setUserId(json.account.id);
        } else {
          setLoginError(loginErrorTemp);
        }
      });
  };
  const handle_logout = () => { 
    localStorage.removeItem("token");
    setLoggedIn(false);
    setUsername("");
    setUserId(null);
    setUpdatingData(true);
  }
  const onHide = () => {
    setShowLoginModal(false)
    setLoginError(undefined)
  }
  function handleErrors(response) {
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem("token");
        setLoggedIn(false);
      }
        throw Error(response.statusText);
        
    }
    return response;
}

  useEffect(() => {
    if (loggedIn) {
      fetch(REACT_BACKEND_SERVER + "/current_user/", {
        method: "GET",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
        .then(handleErrors)
        .then((res) => res.json()) 
        .then((json) => {
          setUsername(json.username);
          setUserId(json.id);
        })
        .catch(function(error) {
          console.log(error);
        })
    }
    document.addEventListener("scroll", () => {
      const backgroundcolor =
        window.scrollY < 5 ? "rgba(16,17,18,0)" : "rgba(16,17,18,0.92)";

      setNavBackground(backgroundcolor);
    });
    window.onbeforeunload = () => {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    };
  }, []);

  const fetchDataByCurrentUser = () => {
    fetch(REACT_BACKEND_SERVER + "/personsByCurrentUser/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        const data = json;
        const array = [];
        data.forEach((d) => {
          array.push(d);
        });
        console.log(array);
        setPersonData(array);
      })
      .catch(function(error) {
        setPersonData([])
        console.log(error);
      });
  }

  const fetchMealChoices = () => {
    fetch(REACT_BACKEND_SERVER + "/meal-choice/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
    .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        const data = json;
        const array = [];
        data.forEach((d) => {
          array.push(d);
        });
        console.log(array);
        setMealChoiceData(array);
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  const fetchMealChoiceAllergens = () => {
    fetch(REACT_BACKEND_SERVER + "/meal-choice-allergen/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
    .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        const data = json;
        const array = [];
        data.forEach((d) => {
          array.push(d);
        });
        console.log(array);
        setMealChoiceAllergenData(array);
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  const fetchAllergens = () => {
    fetch(REACT_BACKEND_SERVER + "/allergen/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
    .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        const data = json;
        const array = [];
        data.forEach((d) => {
          array.push(d);
        });
        console.log(array);
        setallergeneData(array);
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  const fetchHotels = () => {
    fetch(REACT_BACKEND_SERVER + "/hotel/", {
      method: "GET",
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        const data = json;
        const array = [];
        data.forEach((d) => {
          array.push(d);
        });
        console.log(array);
        setHotelData(array);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  const fetchRegistryItems = () => {
    fetch(REACT_BACKEND_SERVER + "/registry-item/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
    .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        const data = json;
        const array = [];
        data.forEach((d) => {
          array.push(d);
        });
        console.log(array);
        setRegistryItemData(array);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  const fetchRegistryPledge = () => {
    fetch(REACT_BACKEND_SERVER + "/registry-pledge/", {
      method: "GET",
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    })
    .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        const data = json;
        const array = [];
        data.forEach((d) => {
          array.push(d);
        });
        console.log(array);
        setRegistryPledgeData(array);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if(loggedIn) {
      fetchDataByCurrentUser()
      fetchMealChoices()
      fetchAllergens()
      fetchMealChoiceAllergens()
      fetchRegistryItems()
      fetchRegistryPledge()
    }
  }, [loggedIn]);

  useEffect(() => {
    if(updatingData) {
      fetchDataByCurrentUser()
      fetchMealChoices()
      fetchRegistryItems()
      fetchRegistryPledge()
      fetchAllergens()
      fetchMealChoiceAllergens()
      setUpdatingData(false)
    }
  }, [updatingData]);

  useEffect(() => {
    fetchHotels()
  }, [])
  return (
    <div className="App">
      <Router>
        
        {window.innerWidth > 1100 ? <Navbar
          navbarState={navbarOpen}
          handleNavbar={handleNavbar}
          navbarColorState={navBackground}
          loggedIn={loggedIn}
          setShowLoginModal={setShowLoginModal}
          personData={personData}
        /> : <NavBarMobile navbarState={navbarOpen} loggedIn = {loggedIn} setShowLoginModal={setShowLoginModal} navbarColorState={navBackground} personData={personData}/>}
        <Switch>
          <Route path="/logIn">
            <LogIn handleLogin={handle_login} />
          </Route>
          <Route path="/registry">
            <Registry registryItemData={registryItemData} registryPledgeData={registryPledgeData} userId={userId} setUpdatingData={setUpdatingData}/>
          </Route>
          <Route path="/profile">
            {!loggedIn ? <Redirect to="/"/>: 
              <Profile userId={userId}  personData={personData} mealChoiceData={mealChoiceData} hotelData={hotelData} handleLogout={handle_logout} setUpdatingData={setUpdatingData}/>
            }
          </Route>
          <Route path="/images">
            {!loggedIn ? <Redirect to="/"/>: 
              <ImageLibrary />
            }
          </Route>
          <Route path="/">
            <HomePage loggedIn={loggedIn} personData={personData} updatingData={ updatingData} setUpdatingData={setUpdatingData} hotelData={hotelData} mealChoiceData={mealChoiceData} allergenData={allergenData} mealChoiceAllergenData={mealChoiceAllergenData}/>
          </Route>
        </Switch>
      </Router>
      <LoginModal show={showLoginModal} setShowLoginModal={setShowLoginModal} onHide ={onHide} setUpdatingData={setUpdatingData} handleLogin={handle_login} loginError={loginError} setLoginError={setLoginError} loggedIn={loggedIn}/>
      <div className={"footer"}>
        <p>Created By: Daniel Timmers & Emily Wyatt</p>
        <p>Contact Us: dan-and-emilys-wedding@outlook.com</p>
      </div>
    </div>
  );
}

export default App;
