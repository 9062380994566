var backendHost;

const hostname = window && window.location && window.location.hostname;
if(hostname === '138.68.189.22') {
  backendHost = 'https://dan-and-emilys-wedding-admin.co.uk';
} else if(hostname === 'www.dan-and-emilys-wedding.co.uk') {
  backendHost = 'https://dan-and-emilys-wedding-admin.co.uk';
} else if(hostname === 'dan-and-emilys-wedding.co.uk') {
  backendHost = 'https://dan-and-emilys-wedding-admin.co.uk';
} else if(hostname === '192.168.1.130') {
  backendHost = 'http://192.168.1.130:8000';
} else if(hostname === '192.168.1.107') {
  backendHost = 'http://192.168.1.107:8000';
} else {
  backendHost = 'http://localhost:8000';
}

export const REACT_BACKEND_SERVER = `${backendHost}`;