import React, { useEffect, useState } from "react";
import styled from "styled-components";
import clsx from 'clsx';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useSpring, animated, config } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBars } from "@fortawesome/free-solid-svg-icons";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import { HashLink } from "react-router-hash-link";
import "./NavBarMobile.css";


import {
    Link as RouterLink,
  } from "react-router-dom";
const useStyles = makeStyles({
    fullList: {
      width: 'auto',
    },
  });
function NavBarMobile(props) {
    const [allEveningOnly, setAllEveningOnly] = useState(true)
    useEffect(()=> {
        var all_evening_only_temp = true
        props.personData.forEach(person => {
          all_evening_only_temp = person.evening_only && all_evening_only_temp
        })
        setAllEveningOnly(all_evening_only_temp)
      }, [props.personData])
    const classes = useStyles();
    const [drawer, setDrawer] = useState(false)
    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setDrawer(open);
    };

    const barAnimation = useSpring({
        from: { transform: "translate3d(0, -10rem, 0)" },
        transform: "translate3d(0, 0, 0)",
      });
    const linkAnimation = useSpring({
    from: { transform: "translate3d(0, 30px, 0)", opacity: 0 },
    to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
    delay: 800,
    config: config.wobbly,
    });
    const navBackground = props.navbarColorState;
    const list = () => (
        <div
          className={clsx( classes.fullList)}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem >
                    <HashLink
                        to="/#home"
                        scroll={(el) =>
                            el.scrollIntoView({ behavior: "smooth", block: "start" })
                        }
                    >
                        Home
                    </HashLink>
                </ListItem>
                <ListItem >
                    <HashLink
                        to="/#ourStory"
                        scroll={(el) =>
                            el.scrollIntoView({ behavior: "smooth", block: "start" })
                        }
                    >
                        Our Story
                    </HashLink>
                </ListItem>
                <ListItem >
                    <HashLink
                        to="/#theDay"
                        scroll={(el) =>
                            el.scrollIntoView({ behavior: "smooth", block: "start" })
                        }
                    >
                        The Day
                    </HashLink>
                </ListItem>
                {!allEveningOnly &&
                <ListItem >
                    <HashLink
                        to="/#food"
                        scroll={(el) =>
                            el.scrollIntoView({ behavior: "smooth", block: "start" })
                        }
                    >
                        Food
                    </HashLink>
                </ListItem>}
                <ListItem >
                    <HashLink
                        to="/#gettingThere"
                        scroll={(el) =>
                            el.scrollIntoView({ behavior: "smooth", block: "start" })
                        }
                    >
                        Getting There
                    </HashLink>
                </ListItem>
                <ListItem >
                    <HashLink
                        to="/#hotels"
                        scroll={(el) =>
                            el.scrollIntoView({ behavior: "smooth", block: "start" })
                        }
                    >
                        Where to Stay
                    </HashLink>
                </ListItem>
                {props.loggedIn &&
                <ListItem >
                    {props.loggedIn &&
                    <RouterLink to="/images">  
                        Pictures
                    </RouterLink>}
                </ListItem>}
                {props.loggedIn &&
                <ListItem >
                    <RouterLink
                        to="/registry"
                    >
                        Registry
                    </RouterLink>
                </ListItem>}

            </List>
        </div>
      );
    return(
        <NavBar style={barAnimation}>
            <FlexContainer style={{ backgroundColor: navBackground}}>
            <NavLinks style={linkAnimation}>
            <NavContainers >
            <a onClick={toggleDrawer(true)}><FontAwesomeIcon icon={faBars} size="3x"/></a>
            <SwipeableDrawer
                anchor ={"left"}
                open={drawer}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                {list()}
            </SwipeableDrawer>
            {props.loggedIn ? (
            <RouterLink to="/profile">  
                <FontAwesomeIcon icon={faUser} size ="2x" />
            </RouterLink>
            ) : (
            // <RouterLink to="/logIn">Log In</RouterLink>
            <a onClick={()=>props.setShowLoginModal(true)}>Log In</a>
            )}
            </NavContainers>
            </NavLinks>
            </FlexContainer>
            

        </NavBar>
    )
   

}
const FlexContainer = styled.div`

  height: 60px;
  transition: background-color 2s ease;
`;

const NavBar = styled(animated.nav)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  font-size: 0.8rem;
`;
const NavLinks = styled(animated.ul)`
  list-style-type: none;
  margin: auto 0;
  position: relative;
  top: 16%;
  padding-left: 0 !important;  

  & a {
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    margin-top:auto;
    margin-bottom:auto;

    &:hover {
      color: rgb(169, 169, 169);
    }

  }
`;
const NavContainers = styled(animated.ul)`
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0 !important;
`;


export default NavBarMobile