import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { useForm, Controller } from "react-hook-form";
import "./RSVPModal.css"
import {ToggleButtonGroup, ToggleButton} from "@material-ui/lab";
import { SportsRugbySharp } from '@material-ui/icons';
import {REACT_BACKEND_SERVER} from '../../app-config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from '@material-ui/core/CircularProgress';


function RSVPModal(props) {
    const StyledToggleButton = withStyles({
      root: {
        color: 'black !important',
        padding: '2px !important',
        paddingLeft: '15px !important',
        paddingRight: '15px !important',
        margin: '5px',
        height: '32px',
        textAlign: 'center',
        backgroundColor: 'white !important',
        border: '1px solid black !important',
        '&:hover': {
          backgroundColor: '#727f86 !important',
          color: 'white !important',
        },
        '&$selected': {
          backgroundColor: '#A1AEB6 !important',
          color: 'white !important',
          '&:hover': {
            backgroundColor: '#727f86 !important',
            color: 'white !important',
          },
        },
      },
      selected: {},
    })(ToggleButton);


    const [RSVPObject, setRSVPObject] = useState({})
    const [sendingRequests, setSendingRequests] = useState(false)
    useEffect(() => {
      const tempRSVPObject = RSVPObject;
      if(props.personData) {
          props.personData.forEach(data => {
              tempRSVPObject[data.id.toString()] = data.RSVP;
          })
          setRSVPObject(tempRSVPObject);
      }
    }, [props.personData])
    const defaultValues = 
      RSVPObject
    
    const { control, handleSubmit} = useForm({defaultValues} );
    function callback () { 
      setSendingRequests(false) 
      props.setModalShow(false)
    }
    function asyncFunction (item, cb) {
      setTimeout(() => {
        cb();
      }, 100);
    }
    const postRequests = (personData, data) => {
      setSendingRequests(true)
      var itemsProcessed = 0;
      personData.forEach(person => {
        asyncFunction(person, () => {
          const newData = person;
          if(data[newData.id] === null) {
            newData.RSVP = "Unknown"
          } else {
            newData.RSVP = data[newData.id]
          }
          fetch(REACT_BACKEND_SERVER + "/persons/"+newData.id+ "/", {
            method: "PUT",
            headers: {
              Authorization: `JWT ${localStorage.getItem("token")}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(newData)
          })
          .then((res) => res.json())
          .then((json) => {     
            props.setUpdatingData(true)
            itemsProcessed++;
            if(itemsProcessed === personData.length) {
              callback();
            }
          });
        })
      })
      
      
    }


    const onSubmit = (data) => {
      postRequests(props.personData, data);

    }
    const body = (
      <div className={"formBorderRSVP"}>
        <form onSubmit={handleSubmit(onSubmit)} className={"rsvpForm"}>
        <a className={"close-icon"} onClick={() => props.setModalShow(false)}><FontAwesomeIcon icon={faTimes} size="2x"/></a>
          <h1 className={"modalHeader"}>RSVP</h1>
          {props.personData.map(person => (
              <div className={"rsvpToggle"}>
              <label className={"modalLabel"}>{person.first_name + " " +  person.last_name}</label>
              <Controller 
              render={props => (
                <ToggleButtonGroup
                exclusive
                aria-label="text alignment"
                {...props}
                onChange={(e, value) => {
                  props.onChange(value);
                }}
              >
                
                <StyledToggleButton value={"Yes"}>
                  Yes
                </StyledToggleButton>
                <StyledToggleButton value={"No"}>
                  No
                </StyledToggleButton>
              </ToggleButtonGroup> 
              )}
              name = {person.id.toString()}
              control={control}
          />
          </div>
          ))}     
          <div>
          {sendingRequests && <CircularProgress size={24} className={"circularProgress"}  />}
          <input type="submit" disabled={sendingRequests}/>
          </div> 
        </form>
      </div>
      );
    return (
        <Modal
        open={props.show}
        onClose={props.onHide}
        >
        {body}
        </Modal>
    )
}
export default RSVPModal;