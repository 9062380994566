function importAll(r) {
  const photos = r.keys().map((item) => {
    const splitString = item.split("_")
    return {
      "src": r(item),
      "width": parseInt(splitString[1]),
      "height": parseInt(splitString[3]),

    }
  });
  return photos;
}

// export const photos = importAll(require.context('./ImageList/Sorted', false, /\.(png|jpe?g|svg|jfif)$/));
export const photos = importAll(require.context('./ImageList/Sorted', false, /\.(png|jpe?g|svg|jfif)$/));
export const highResPhotos= importAll(require.context('./ImageList/Sorted_Dev', false, /\.(png|jpe?g|svg|jfif)$/));