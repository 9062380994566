import "./Home.css";
import React, {useState} from "react";
import Countdown from "../Countdown/Countdown";
import Button from 'react-bootstrap/Button';
import RSVPModal from "../RSVPModal/RSVPModal";


export default function Home(props) {

  const [modalShow, setModalShow] = useState(false);
  return (
    <div className={"home"} id={"home"}>
      <div className="section-content">
        <div className={"bottom-page-content"}>
        <p className={"header"}>Daniel & Emily</p>
        <p className={"date-p"}>
        {props.loggedIn &&<span>August 15, 2022 •</span>} The Kingscote Barn, Gloucestershire
        </p>
          {props.loggedIn && <Button className={"rsvp_button"} onClick={() =>  setModalShow(true)}>RSVP</Button>}
        <RSVPModal show={modalShow} updatingData={props.updatingData} setModalShow={setModalShow} onHide ={()=> setModalShow(false)} personData={props.personData} setUpdatingData={props.setUpdatingData}/>
        {props.loggedIn && <Countdown />}
        </div>
      </div>
    </div>
  );
}
