import React, {useEffect, useState} from "react";
import Home from "../Home/Home";
import About from "../About/About";
import Day from "../Day/Day";
import GettingThere from "../GettingThere/GettingThere";
import Hotels from "../Hotels/Hotels";
import Food from "../Food/Food";
import ImageLibrary from "../ImageLibrary/ImageLibrary";

function HomePage(props) {
  const [allEveningOnly, setAllEveningOnly] = useState(true)
  useEffect(()=> {
    var all_evening_only_temp = true
    props.personData.forEach(person => {
      all_evening_only_temp = person.evening_only && all_evening_only_temp
    })
    setAllEveningOnly(all_evening_only_temp)
  }, [props.personData])
  return (
    <div>
      <Home loggedIn={props.loggedIn} updatingData={props.updatingData} personData={props.personData} setUpdatingData={props.setUpdatingData} />
      <About />
      <Day loggedIn={props.loggedIn}/>
      {!allEveningOnly && 
      <Food mealChoiceData={props.mealChoiceData} allergenData={props.allergenData} mealChoiceAllergenData={props.mealChoiceAllergenData}/>
      }
      <GettingThere />
      <Hotels hotelData={props.hotelData}/>
      {/* {props.loggedIn && <ImageLibrary />} */}
    </div>
  );
}

export default HomePage;
