import React, { useState, useEffect } from "react";
import "./Hotels.css"
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { faInfoCircle, faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const useStyles = makeStyles((theme) => ({
    root: {
      width:"90%",
      margin: "auto",
      display:"flex"
    },
  }));

const moreInfoLinks = (hotel) => (
    <div className="hotel-info-container">
        <div className="info-object">
            <FontAwesomeIcon icon={faInfoCircle} size="1x" />
            <a className={"hotel-link"} href={hotel.url} target="_blank">More Info</a>
        </div>
        <div className="info-object">
            <FontAwesomeIcon icon={faMapMarkedAlt} size="1x" />
            <a className={"hotel-link"} href={hotel.map_url} target="_blank">Show in Maps</a>
        </div>
    </div>
)

function Hotels(props) {
    const classes = useStyles();
    const size = useWindowSize();
    function useWindowSize() {
        const isClient = typeof window === "object";

        function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        };
        }

        const [windowSize, setWindowSize] = useState(getSize);

        useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
        }, []);

        return windowSize;
    }

    return(
        <div className={"pageWrapper"}>
            <div className={"hotels-section"} id={"hotels"}>
                <p className={"header-text"}>Where to Stay</p>
                <div className={"hotel-grid"}>
                <Grid container spacing={3} style={{margin: "0", width: "100%"}}>
                {props.hotelData.map(hotel => (
                    <Grid item xs={12}>
                        <Card className={classes.root}> 
                            {/* <CardMedia className={classes.media} image={hotel.image}/> */}
                            <img className={"image"} src={hotel.image}/>
                            <div className={"hotel-info"}>
                                <p className={"hotel-name"}>{hotel.name}</p>
                                <p>
                                    <span className={"hotel-header"}>Address: </span>
                                    {hotel.address}
                                </p>
                                {size.width > 700 && <div>{moreInfoLinks(hotel)}</div>}
                            </div>
                        </Card>
                        {size.width <= 700 &&
                        <Card className={classes.root}> 
                        <div className={"hotel-info"}>{moreInfoLinks(hotel)}</div>
                        </Card>}
                    </Grid>
                ))}
                </Grid>
                </div>
            </div>
        </div>
    )
}

export default Hotels;