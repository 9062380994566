import React, { useState, useEffect } from "react";
import "./About.css";
import groom from "../../Assets/groom4.jpg";
import bride from "../../Assets/bride1.jpg";
import relationship from "../../Assets/our-relationship2.jpg";
export default function About() {
  const size = useWindowSize();
  function useWindowSize() {
    const isClient = typeof window === "object";

    function getSize() {
      return {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined,
      };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
      if (!isClient) {
        return false;
      }

      function handleResize() {
        setWindowSize(getSize());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
  }
  return (
    <div className={"pageWrapper"}>
    <div className={"about-section"} id={"ourStory"}>
      <p className={"header-text"}>Our Story</p>
      <div
        className={size.width <= 1024 ? "card-deck-small" : "card-deck-big"}
      >
        <div className={"about-card"}>
          <img src={groom} className={"card-image"} alt={"groom"} />
          <p className={"card-head"}>Daniel's Story</p>
          <p className={"card-text"}>
          Dan grew up in the North West (Preston, Lancashire) where he spent most of his childhood 
          playing football on the green outside his home, apologising for repeatedly 
          hitting people’s windows. He loved to blame such indiscretions on his younger 
          sister Chloe. After starting high school at Hutton Grammar, he found a 
          passion for rugby where he played full back for their squad. This is also 
          where he met a number of his lifelong friends. Dan was always a maths nerd 
          and could bore anyone on the topic of economics, and therefore decided to 
          fly the nest in 2016 to study MORSE (Maths, Stats & Economics) at Warwick 
          University…
          </p>
        </div>
        <div className={"about-card"}>
          <img src={bride} className={"card-image"} alt={"bride"} />
          <p className={"card-head"}>Emily's Story</p>
          <p className={"card-text"}>
          Growing up in Frampton-on-Severn Gloucestershire, Emily loved her countryside 
          upbringing. She spent much of her life cycling around the village with her 
          friends buying bon-bons from the local post office and being an annoying younger 
          sister to two siblings (Naomi and Harry). She also enjoyed playing and coaching 
          tennis at the local tennis club and was a proud member of young farmers where 
          she partook in milking competitions and bail and twine fashion contests. After 
          completing her A-levels, she found her passion for Computer Science and went to 
          study this for her undergraduate at Warwick University…
          </p>
        </div>
        <div className={"about-card"}>
          <img src={relationship} className={"card-image"} alt={"bride & groom"} />
          <p className={"card-head"}>Our Relationship</p>
          <p className={"card-text"}>
          Dan and Emily met on the first day of university having been assigned the same 
          personal tutor. In 2nd year, their friendship blossomed, and they began dating.
          Many of their dates involved picnics and rugby. After university they moved to 
          London, with Uni friends, to pursue careers in Finance and Software Engineering. 
          During a visit to Ingleton, North Yorkshire, Dan proposed to Emily 
          whilst taking a hike around Ingleton Waterfalls. Emily said yes, and the 
          painstaking process of planning a wedding began. In recent months, they have also 
          bought their first home – they moved to Hornchurch, Essex, in November 2021. 
          </p>
        </div>
      </div>
    </div>
    </div>
  );
}
