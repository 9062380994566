import React, {useState, useEffect} from "react";
import '../.././styles.scss';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { ProgressBar, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Contribute from "../Contribute/Contribute";
import "./Registry.css";
import PaymentDetailsModal from "../PaymentDetailsModal/PaymentDetailsModal";



const pledgeAvailable = (item, userPledges, itemsPledgedAmount) => (
    Math.round((item.amount_required - itemsPledgedAmount[item.id]) * 100)/100 == 0 && (userPledges[item.id] == undefined || userPledges[item.id].amountPledged == 0 || userPledges[item.id].payed == true)
)

const pledgeEditBox = (item, userPledges, onClick, itemsPledgedAmount) => (
    <div className={"pledge-edit-box"}>
        {item.name !== "Day Trips" ?
        <><div style={{width: "60%", marginTop: "auto", marginBottom: "auto", paddingRight: "5px"}}>
            <Col><ProgressBar striped variant="success" animated now={itemsPledgedAmount[item.id]/item.amount_required * 100}  style ={{height: "30px"}}/></Col>
        </div>
        <div style={{display: "flex", width: "40%", paddingRight: "5px"}}>
            <Button className={"pledgeButton"} onClick={() => onClick(item.id)} disabled={pledgeAvailable(item, userPledges, itemsPledgedAmount)}>{userPledges[item.id] ? "Edit Pledge" : "Contribute"}</Button>
        </div> </> :
        <div style={{display: "flex", width: "100%", marginLeft: "20px", margin: "0", paddingRight: "10px"}}>
            <Button className={"pledgeButton"} style={{margin: "0", width: "100%"}} onClick={() => onClick(item.id)}>{userPledges[item.id] ? "Edit Pledge" : "Contribute"}</Button>
        </div>
        }
    </div>
)

function Registry(props) {

    const pledgeStatusBox = (item, userPledges) => (
        <>
        {userPledges[item.id] && userPledges[item.id].payed === false &&
            <div className={"pledgeStatus"} style={{backgroundColor:"#FCE6A9"}}>Pledged £{userPledges[item.id].amountPledged}
            <Tooltip title="Pay Now"><a style={{marginTop: "auto", marginBottom: "auto"}} onClick={iconOnClick}> 
                        <FontAwesomeIcon style={{paddingLeft: "10px"}} className={"fa-icon"} icon={faCreditCard} size='lg'/>
                    </a></Tooltip>
            </div>
            
        }
        {userPledges[item.id] && userPledges[item.id].payed === true &&
            <div className={"pledgeStatus"} style={{backgroundColor:"#87DCC0"}}>Gift Received</div>
        }
        </>
    )

    const iconOnClick = () => {
        setPaymentDetailsShow(true)
    }

    const size = useWindowSize();
    function useWindowSize() {
        const isClient = typeof window === "object";

        function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        };
        }

        const [windowSize, setWindowSize] = useState(getSize);

        useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
        }, []);

        return windowSize;
    }

    const useStyles = makeStyles((theme) => ({
        root: {
        width:"100%",
        margin: "auto",
        display:"flex",
        position: "relative"
        },
    }));
    const classes = useStyles();
    const [userPledges, setUserPledges] = useState(new Map());
    const [itemsPledgedAmount, setItemsPledgedAmount] = useState(new Map());
    const [modalShow, setModalShow] = useState(false);
    const [openModal, setOpenModal] = useState(undefined);
    const [paymentDetailsShow, setPaymentDetailsShow] = useState(false);

    const getUserPledges = (registryPledgeData, userId) => {
        var userPledges = new Map(); 
        registryPledgeData.forEach(pledge => {
        if (pledge.account === userId) {
            userPledges[pledge.registry_item] = {amountPledged: pledge.amount_pledged, payed: pledge.payed, message: pledge.message, id: pledge.id}
        }
        })
        return userPledges;
    }

    const getPledgedAmounts = (registryItemData, registryPledgeData) => {
        var itemsPledgedAmounts = new Map();
        registryItemData.forEach( item => {
            var totalPledged = 0
            registryPledgeData.forEach(pledge => {
                if (pledge["registry_item"] === item["id"]) {
                    totalPledged += pledge["amount_pledged"]
                }
            })
            itemsPledgedAmounts[item["id"]] = totalPledged
        })
        return itemsPledgedAmounts
    }

    useEffect(() => {
        setItemsPledgedAmount(getPledgedAmounts(props.registryItemData, props.registryPledgeData))
        setUserPledges(getUserPledges(props.registryPledgeData, props.userId))
    }, [props.registryPledgeData, props.registryItemData])

    const onClick = (id) => {
        if (modalShow === false) {
          setModalShow(true);
          setOpenModal(id)
        }
      }
   
    
    return (
        <div className="registry">
            <div className={"registry-section"}>
            <p className={"header-text registry-title-text"}>Honeymoon Registry</p>
            <div className={"registry-message"}>
                <p>As we're already lucky enough to have all the physical items we need to start our lives together, we've created a honeymoon registry for anyone who would like to give a gift via a small contribution to one of the items below. Please feel no obligation to do so, the best gift we could receive is you there on our special day.</p>
                <p>We will be travelling to Crete in September for some rest & relaxation.</p>
            </div>
            <Grid container spacing={2} style={{margin: "0", width: "100%"}}>
                {props.registryItemData.map(item => (
                    <Grid item xs={12}>
                        {size.width <= 750 &&
                        <Card className={classes.root} style={{backgroundColor: "transparent"}}>
                            <div style={{width: "100%"}}>{pledgeStatusBox(item, userPledges)}</div>
                        </Card>}
                        <Card className={classes.root}>
                            {size.width > 750 && <div>{pledgeStatusBox(item, userPledges)}</div>}
                            <img className={"image"} src={item.image}/>
                            <div className={"item-info"}>
                                <div className={"item-name"}>
                                    <p className={"item-text"} style={{paddingRight: "60px"}}>{item.name}</p>
                                    <p className={"empty-box"}></p>
                                </div>
                                {item.name !== "Day Trips" ? 
                                <p className={"item-amount"}>
                                    {Math.round((item.amount_required - itemsPledgedAmount[item.id]) * 100)/100 == 0 ?
                                    <>Gift Fulfilled</> :
                                    <>Amount Remaining: £{Math.round((item.amount_required - itemsPledgedAmount[item.id]) * 100)/100}</>}
                                </p> : 
                                <p className={"item-amount"}>
                                Amount Pledged: £{Math.round((itemsPledgedAmount[item.id]) * 100)/100}
                                </p>}
                                {size.width > 750 && <div>{pledgeEditBox(item, userPledges, onClick, itemsPledgedAmount)}</div>}
                                <Contribute userId={props.userId} show={modalShow} openModal={openModal} data={item} onHide = {()=> setModalShow(false)} amountPledged={itemsPledgedAmount[item.id]} setUpdatingData={props.setUpdatingData} pledgeData={userPledges[item.id]}/>
                            </div>
                        </Card>
                        {size.width <= 750 &&
                        <Card className={classes.root}>
                            <div style={{width: "100%"}}>{pledgeEditBox(item, userPledges, onClick, itemsPledgedAmount)}</div>
                        </Card>}
                    </Grid>
                ))}
            </Grid>
            </div>
            <PaymentDetailsModal onHide={() => setPaymentDetailsShow(false)} show={paymentDetailsShow}/>
        </div>
    )
}

export default Registry;

