import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { useForm, Controller } from "react-hook-form";
import "./ProfileModal.css"
import {ToggleButtonGroup, ToggleButton} from "@material-ui/lab";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ChatBubbleOutlineSharp, PinDropTwoTone } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import {REACT_BACKEND_SERVER} from '../../app-config';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';


import _ from "lodash/fp";


function ProfileModal(props) {
    const StyledToggleButton = withStyles({
      root: {
        color: 'black !important',
        padding: '2px !important',
        paddingLeft: '15px !important',
        paddingRight: '15px !important',
        margin: '5px',
        height: '32px',
        textAlign: 'center',
        backgroundColor: 'white !important',
        border: '1px solid black !important',
        '&:hover': {
          backgroundColor: '#727f86 !important',
          color: 'white !important',
        },
        '&$selected': {
          backgroundColor: '#A1AEB6 !important',
          color: 'white !important',
          '&:hover': {
            backgroundColor: '#727f86 !important',
            color: 'white !important',
          },
        },
      },
      selected: {},
    })(ToggleButton);
    const StyledSelect = withStyles({
        root: {
          color: 'black !important',
          paddingTop: '9px !important',
          paddingBottom: '9px !important',
          '&:hover': {
            backgroundColor: '#727f86 !important',
            color: 'white !important',
            borderColor: 'red'
          },
          // margin: '5px',
          // height: '32px',
          // textAlign: 'center',
          backgroundColor: 'white !important',
        }
      })(Select);

    const StyledTextField = withStyles({
      root: {
        backgroundColor: 'white !important',
        width: '65%'
      }
      
    })(TextField)

    const StyledLargeTextField = withStyles({
      root: {
        backgroundColor: 'white !important',
        width: '100%'
      }
    })(TextField)

    const [sendingRequests, setSendingRequests] = useState(false)
    const [defaultObject, setDefaultObject] = useState({})
    useEffect(() => {
      const tempDefaultObject = defaultObject;
      if(props.data) {
          tempDefaultObject['email'] = props.data.email
          tempDefaultObject['RSVP'] = props.data.RSVP
          tempDefaultObject['meal_choice_starter'] = props.data.meal_choice_starter
          tempDefaultObject['meal_choice_main'] = props.data.meal_choice_main
          tempDefaultObject['meal_choice_dessert'] = props.data.meal_choice_dessert
          tempDefaultObject['little_person'] = props.data.little_person
          if (props.data.hotel_other !== null) {
            tempDefaultObject['hotel'] = "other"
          } else {
            tempDefaultObject['hotel'] = props.data.hotel
          }
          tempDefaultObject['hotel_other'] = props.data.hotel_other
      }
    }, [props.data])
    const defaultValues = defaultObject;
    
    const { register, control, handleSubmit, watch, errors} = useForm({defaultValues} );
    const watchHotel = watch("hotel");
    const watchLittlePerson = watch("little_person")
    
    const postRequest = (data, formData) => {
      setSendingRequests(true);
      if(formData['hotel'] === "other") {
        formData['hotel'] = null
      } else {
        formData['hotel_other'] = null
      }
      if(formData["RSVP"] === null) {
        formData["RSVP"] = "Unknown"
      }
      const arrayKeys =  Object.keys(formData);
      let newData = data;
      arrayKeys.forEach(key => {
        newData[key] = formData[key];
      })
      fetch(REACT_BACKEND_SERVER + "/persons/"+newData.id+ "/", {
        method: "PUT",
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newData)
      })
      .then((res) => res.json())
      .then((json) => {     
        props.setUpdatingData(true)
        setSendingRequests(false)
        props.setModalShow(false);
      });


      
    }

    const onSubmit = (data) => {
      postRequest(props.data, data);
    }
    const body = (
      <div className={"formBorderProfile"}>
        <form onSubmit={handleSubmit(onSubmit)} className={"profileForm"}>
        <a className={"close-icon"} onClick={() => props.setModalShow(false)}><FontAwesomeIcon icon={faTimes} size="2x"/></a>
          <h1 className={"modalHeader"}>{props.data.first_name +  " " + props.data.last_name}</h1>
          
          <div className={"profileInput"}>
          <label className={"modalLabel"}>Email</label>
          <Controller 
              render={controllerProps => (
                  <StyledTextField inputProps = {{style: {paddingLeft: "10px"}}} {...controllerProps} />
              )}
              id = "email"
              name = {"email"}
              control={control}
              rules={{
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Entered value does not match email format"
                }
              }}
          />
          
          </div>
          {errors.email && <p className={"error"}>{errors.email.message}</p>}
          <div className={"profileInput"}>
          <label className={"modalLabel"}>RSVP</label>
          <Controller 
              render={props => (
                <ToggleButtonGroup
                exclusive
                aria-label="text alignment"
                {...props}
                onChange={(e, value) => {
                  props.onChange(value);
                }}
              >
                
                <StyledToggleButton value={"Yes"}>
                  Yes
                </StyledToggleButton>
                <StyledToggleButton value={"No"}>
                  No
                </StyledToggleButton>
              </ToggleButtonGroup> 
              )}
              name = {"RSVP"}
              control={control}
          />
          </div>
          {!props.data.evening_only &&
          <>
          <div className={"profileInput"}>
          <label className={"modalLabel"}>Little Person?</label> 
          <Controller 
              render={controllerProps => (
                  <Checkbox color="default" {...controllerProps} checked={controllerProps.value} onChange={(e, value) => {
                    controllerProps.onChange(value);
                  }}/>
              )}
              name = {"little_person"}
              control={control}
          />
          </div>
          <div className={"profileInput"}> 
          <label className={"modalLabel"}>Starter Option</label>
          <Controller 
              render={controllerProps => (
                  <StyledSelect {...controllerProps} variant="outlined">
                      <MenuItem value={null} >
                            <em>None</em>
                        </MenuItem>
                      {props.mealChoiceData.map(choice => {
                          if(choice.course==="Starter") {
                            if(choice.childs_option && watchLittlePerson) {
                              return(
                                  <MenuItem value={choice.id}>{choice.name}</MenuItem>
                              )
                            } else if (!choice.childs_option && !watchLittlePerson) {
                              return(
                                <MenuItem value={choice.id}>{choice.name}</MenuItem>
                              )
                            }
                          }
                      })}
                  </StyledSelect>
              )}
              name = {"meal_choice_starter"}
              control={control}
          />
          </div>
          <div className={"profileInput"}>
          <label className={"modalLabel"}>Main Option</label>
          <Controller 
              render={controllerProps => (
                  <StyledSelect {...controllerProps} variant="outlined">
                      <MenuItem value={null} >
                            <em>None</em>
                        </MenuItem>
                      {props.mealChoiceData.map(choice => {
                          if(choice.course==="Main") {
                            if(choice.childs_option && watchLittlePerson) {
                              return(
                                  <MenuItem value={choice.id}>{choice.name}</MenuItem>
                              )
                            } else if (!choice.childs_option && !watchLittlePerson) {
                              return(
                                <MenuItem value={choice.id}>{choice.name}</MenuItem>
                              )
                            }
                          }
                      })}
                  </StyledSelect>
              )}
              name = {"meal_choice_main"}
              control={control}
          />
          </div>
          <div className={"profileInput"}>
          <label className={"modalLabel"}>Dessert Option</label>
          <Controller 
              render={controllerProps => (
                  <StyledSelect {...controllerProps} variant="outlined">
                      <MenuItem value={null} >
                            <em>None</em>
                        </MenuItem>
                      {props.mealChoiceData.map(choice => {
                          if(choice.course==="Dessert") {
                            if(choice.childs_option && watchLittlePerson) {
                              return(
                                  <MenuItem value={choice.id}>{choice.name}</MenuItem>
                              )
                            } else if (!choice.childs_option && !watchLittlePerson) {
                              return(
                                <MenuItem value={choice.id}>{choice.name}</MenuItem>
                              )
                            }
                          }
                      })}
                  </StyledSelect>
              )}
              name = {"meal_choice_dessert"}
              control={control}
          />
          </div>
          </>
          }
          <div className={"profileInput"}>
          <label className={"modalLabel"}>Where are you staying?</label>
          <Controller 
              render={controllerProps => (
                  
                  <StyledSelect {...controllerProps} variant="outlined">
                      <MenuItem value={null} >
                            <em>None</em>
                        </MenuItem>
                      {props.hotelData.map(hotel => {
                            return(
                                <MenuItem value={hotel.id}>{hotel.name}</MenuItem>
                            )
                      })}
                      <MenuItem value={"other"}>Other</MenuItem>
                  </StyledSelect>
              )}
              name = {"hotel"}
              control={control}
          />
          </div>
          {(watchHotel === "other") && <><label className={"modalLabel"}>Please provide details:</label> <Controller 
              render={controllerProps => (
                  <StyledLargeTextField inputProps = {{style: {paddingLeft: "10px"}}} {...controllerProps} />
              )}
              name = {"hotel_other"}
              control={control}
          /></>}
          <div>
          {sendingRequests && <CircularProgress size={24} className={"circularProgress"}  />}
          <input className="modal-input" type="submit" disabled={sendingRequests}/>
          </div>
        </form>
      </div>
      );
    return (
        <Modal
        open={props.modalShow && props.openModal===props.data.id}
        onClose={props.onHide}
        >
        {body}
        </Modal>
    )
}
export default ProfileModal;