import React from 'react';
import Modal from '@material-ui/core/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";


function PaymentDetailsModal(props) {
  const onClickRevolut = () => {
    window.open("https://revolut.me/danemilyswedding", "_blank")
    props.onHide()
  }
  const onClickDone = () => {
    props.onHide()
  }
  const body = (
    <div className={"payBorder"}>
      <div className={"giftPayText"}>Thank you very much for the gift pledge. We have been experiencing difficulties with out revolut payment system you can now pay by direct transfer or through revolut:</div>
      <div className={"BankDetails"} style={{textAlign: "center", paddingBottom: "10px"}}>
          <div className={"accountName"}>Account Name: Mr Daniel Timmers</div>
          <div className={"sortCode"}>Sort Code: 04-00-75</div>
          <div className={"accountNumber"}>Account Number: 04916549</div>
      </div>
      <div style={{display: "flex", alignSelf: "center", justifyContent: "space-between"}}>
        <Button className={"giftPayButton"} onClick={() =>  onClickRevolut() }>Pay By Revolut <FontAwesomeIcon style={{paddingLeft: "10px"}} icon={faCreditCard} size='lg'/></Button>
        <Button className={"giftPayButton"} onClick={() =>  onClickDone()}><div>Close<div> </div>(click <FontAwesomeIcon style={{paddingLeft: "5px", paddingRight: "5px"}} icon={faCreditCard} size='lg'/> at any time)</div></Button>
        </div>
    </div>
    );
  return (
      <Modal
      open={props.show}
      onClose={(_, reason) => {reason !== "backdropClick" && onClickDone()}}
      >
      {body}
      </Modal>
  )
}
export default PaymentDetailsModal;