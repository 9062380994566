import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSpring, animated, config } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import "./Navbar.css";
import {
  Link as RouterLink,
} from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Navbar = (props) => {
  const [allEveningOnly, setAllEveningOnly] = useState(true)
  const barAnimation = useSpring({
    from: { transform: "translate3d(0, -10rem, 0)" },
    transform: "translate3d(0, 0, 0)",
  });

  const linkAnimation = useSpring({
    from: { transform: "translate3d(0, 30px, 0)", opacity: 0 },
    to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
    delay: 800,
    config: config.wobbly,
  });

  useEffect(()=> {
    var all_evening_only_temp = true
    props.personData.forEach(person => {
      all_evening_only_temp = person.evening_only && all_evening_only_temp
    })
    setAllEveningOnly(all_evening_only_temp)
  }, [props.personData])
  

  return (
    <>
      <NavBar style={barAnimation}>
        <FlexContainer style={{ backgroundColor: props.navbarColorState }}>
          <NavLinks style={linkAnimation}>
            <HashLink
              to="/#home"
              scroll={(el) =>
                el.scrollIntoView({ behavior: "smooth", block: "start" })
              }
            >
              Home
            </HashLink>
            <HashLink
              to="/#ourStory"
              scroll={(el) =>
                el.scrollIntoView({ behavior: "smooth", block: "start" })
              }
            >
              Our Story
            </HashLink>
            
            <HashLink
              to="/#theDay"
              scroll={(el) =>
                el.scrollIntoView({ behavior: "smooth", block: "start" })
              }
            >
              The Day
            </HashLink>
            
            {!allEveningOnly && 
            <HashLink
            to="/#food"
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            >
              Food
            </HashLink>}
            
            <HashLink
              className={"nav-item nav-logo"}
              to="/#home"
              scroll={(el) =>
                el.scrollIntoView({ behavior: "smooth", block: "start" })
              }
            >
              Daniel & Emily
            </HashLink>
            
            <HashLink
              to="/#gettingThere"
              scroll={(el) =>
                el.scrollIntoView({ behavior: "smooth", block: "start" })
              }
            >
              Getting There
            </HashLink>
            
            <HashLink
              to="/#hotels"
              scroll={(el) =>
                el.scrollIntoView({ behavior: "smooth", block: "start" })
              }
            >
              Where to Stay
            </HashLink>
                     
            {props.loggedIn &&
            <RouterLink to="/images">  
              Pictures
              </RouterLink>}

            {props.loggedIn && 
              <RouterLink to="/registry">  
                Registry
              </RouterLink>}

            {props.loggedIn ? (
              <RouterLink to="/profile">  
                <FontAwesomeIcon icon={faUser} size='2x'/>
              </RouterLink>
            ) : (
              // <RouterLink to="/logIn">Log In</RouterLink>
              <a onClick={()=>props.setShowLoginModal(true)}>Log In</a>
            )}
          
          </NavLinks>
        </FlexContainer>
      </NavBar>
    </>
  );
};

export default Navbar;

const NavBar = styled(animated.nav)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
   {
    /*background: #2d3436;*/
  }
  z-index: 1;
  font-size: 0.8rem;
`;

const FlexContainer = styled.div`
  justify-content: center;
  height: 60px;
  transition: background-color 2s ease;
`;

const NavLinks = styled(animated.ul)`
  list-style-type: none;
  margin: auto 0;
  position: relative;
  top: 25%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-inline-end: 40px;

  & a {
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid transparent;
    margin: 0 1.5rem;
    transition: all 200ms linear 0s;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: rgb(169, 169, 169);
    }

    &:last-child {
      position: absolute;
      right: 0;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;
