import React, {useState} from "react";
import { Modal } from "@material-ui/core";
import "./Carousel.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";

const Carousel = ({isOpen, photos, onClose, currentImage, highResPhotos, onPrev, onNext}) => {

    const [src, setSrc] = useState("")

    useEffect(() => {
        setSrc(photos[currentImage].src)
    }, [currentImage])
      
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <div className={"carousel-modal"}>
                <div className={"carousel-header"}>
                    <a className={"close-icon"} onClick={onClose}><FontAwesomeIcon icon={faTimes} size="2x"/></a>
                </div>
                <div className={"carousel-content"}>
                    <a onClick={onPrev} className={"close-icon"}><FontAwesomeIcon icon={faArrowLeft} size="2x"/></a>
                     <img src={src} className={"carousel-img"} />
                     <a onClick={onNext} className={"close-icon"}><FontAwesomeIcon icon={faArrowRight} size="2x"/></a>
                </div>
                <div className={"carousel-footer"}>
                    <p className={"close-icon"} style={{userSelect: "none", color: "white"}} >{currentImage+1}/{photos.length}</p>
                    <a className={"close-icon"} href={highResPhotos[currentImage].src} download><FontAwesomeIcon icon={faDownload}  size="lg"/></a>
                </div>
            </div>
            
        </Modal>
    )
}

export default Carousel;