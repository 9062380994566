import React from "react";
import "../../App.css";
import { useForm } from "react-hook-form";
import "./Login.css";
import _ from "lodash/fp";

function LogIn(props) {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    props.handleLogin(data);
  };
  return (
    <div className={"logIn"}>
      <div className={"formBorder"}>
        <form onSubmit={handleSubmit(onSubmit)} className={"logInForm"}>
          <h1>Log In</h1>
          <label>Username</label>
          <input
            name="username"
            ref={register({
              required: "Required",
            })}
          />
          {_.get("username.type", errors) === "required" && (
            <p className={"error"}>This field is required</p>
          )}
          <label>Password</label>
          <input
            id="password"
            name="password"
            ref={register({
              required: "required",
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.,])[A-Za-z\d@$!%*?&.,]{8,}$/i,
            })}
            type="password"
          />
          {_.get("password.type", errors) === "pattern" && (
            <p className={"error"}>
              Password must contain 8+ characters, contatin at least one capital
              cetter, number and special character
            </p>
          )}
          <input type="submit" />
        </form>
      </div>
    </div>
  );
}
export default LogIn;
