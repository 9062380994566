import React, { useState } from "react";
import "./Day.css";
import Ceremony from "../Ceremony/Ceremony";
import Dinner from "../Dinner/Dinner";
import Party from "../Party/Party";
import { propTypes } from "react-bootstrap/esm/Image";
export default function Day(props) {
  const [tabSelected, setTabSelected] = useState(1);
  return (
    <div className={"pageWrapper"}>
    <div className={"day-section"} id={"theDay"}>
      <p className={"header-text"}>The Day</p>
      <div className="day-content">
      <div className={"tab-bar"}>
        <div
          className={tabSelected === 1 ? "tab-selected" : "tab"}
          onClick={() => setTabSelected(1)}
        >
          Ceremony
        </div>
        <div
          className={tabSelected === 2 ? "tab-selected" : "tab"}
          onClick={() => setTabSelected(2)}
        >
          Dinner
        </div>
        <div
          className={tabSelected === 3 ? "tab-selected" : "tab"}
          onClick={() => setTabSelected(3)}
        >
          Party
        </div>
      </div>
      {tabSelected === 1 && <Ceremony loggedIn={props.loggedIn}/>}
      {tabSelected === 2 && <Dinner loggedIn={props.loggedIn}/>}
      {tabSelected === 3 && <Party loggedIn={props.loggedIn}/>}
      </div>
    </div>
    </div>
  );
}
