import React, {useState} from "react";
import "../../App.css";
import "./Profile.css";
import Button from 'react-bootstrap/Button';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ProfileModal from "../ProfileModal/ProfileModal";
import { HashLink } from "react-router-hash-link";
import { faUtensils, faBed, faTimes } from "@fortawesome/free-solid-svg-icons";
import Switch from '@material-ui/core/Switch';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const useStyles = makeStyles({
  tableHead: {
    backgroundColor: "rgba(114, 127, 134, 1) !important",
  },
  tableContainer: {
    width: "95%",
    margin:"auto",
    fontWeight: "bold",
    borderRadius: "6px"
  },
  table: {
    backgroundColor: "#cbd3d7 !important",
  }
});

const StyledTableCell = withStyles({
  root: {
    fontFamily: "Montserrat !important",
    paddingTop: "9px",
    paddingBottom: "9px",
    fontWeight: "800",
  },
})(TableCell);

const StyledTableHeaderCell = withStyles({
  root: {
    fontWeight: "bold",
    color: "white",
  },
})(StyledTableCell);


function Profile(props) {
  
  var all_evening_only = true;
  props.personData.forEach(person => {
    all_evening_only = person.evening_only && all_evening_only
  })
  const showFood = !all_evening_only;
  const onClick = (id) => {
    if (modalShow === false) {
      setModalShow(true);
      setOpenModal(id)
    }
  }
  const classes = useStyles();
  const [modalShow, setModalShow] = useState(false);
  const [openModal, setOpenModal] = useState(undefined);
 
  let menuChoiceMap = new Map();
  props.mealChoiceData.forEach(choice => {
    menuChoiceMap[choice.id] = choice.name;
  })

  let hotelMap = new Map();
  props.hotelData.forEach(hotel => {
    hotelMap[hotel.id] = hotel.name;
  })
  
  return (
    <div className={"profile"}>
      <div className={"profilePageBorder"}>
        <div className={"profileHeaderContainer"}>
        <h2 className={"profileHeader"}>Welcome{' '} {props.personData.map((row) => {
        if (row === props.personData[props.personData.length-2]){
          return <span>{row.first_name}{' & '}</span>
        } else if (row === props.personData[props.personData.length-1]) {
          return <span>{row.first_name}</span>
        } else {
          return <span>{row.first_name}{', '}</span>
        }
        })}
        </h2>
        <div className={"buttons-div"}>
        <HashLink to="/#home" style={{ textDecoration: 'none' }}>
      <Button className={"logOutButton"} onClick={() => props.handleLogout()} >Logout</Button>
      </HashLink>
      <HashLink to="/#home" className={"close-button"}> <FontAwesomeIcon icon={faTimes} size="2x" className={"close-icon"}/> </HashLink>
      </div>
        </div>
      <TableContainer className={classes.tableContainer}>
        {showFood ?
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <StyledTableHeaderCell />
              <StyledTableHeaderCell>Name</StyledTableHeaderCell>
              <StyledTableHeaderCell>Email</StyledTableHeaderCell>
              <StyledTableHeaderCell>Attending</StyledTableHeaderCell>
              <StyledTableHeaderCell>Starter</StyledTableHeaderCell>
              <StyledTableHeaderCell>Main</StyledTableHeaderCell>
              <StyledTableHeaderCell>Dessert</StyledTableHeaderCell>
              <StyledTableHeaderCell>Hotel</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.personData.map((row) => (
              <TableRow>
                <StyledTableCell>
                  <IconButton
                    color="inherit"
                    aria-label="Edit"
                    component="span"
                    onClick={() => onClick(row.id)}
                  >
                    <EditIcon />
                  </IconButton>
                  <ProfileModal setModalShow={setModalShow} setUpdatingData={props.setUpdatingData} modalShow={modalShow} openModal={openModal} onHide ={()=> setModalShow(false)} data={row} mealChoiceData={props.mealChoiceData} hotelData={props.hotelData}/>
                </StyledTableCell>
                <StyledTableCell>{row.first_name + " " + row.last_name}</StyledTableCell>
                <StyledTableCell>{row.email}</StyledTableCell>
                <StyledTableCell>
                  {row.RSVP === "Yes" ? (
                    <CheckIcon />
                  ) : row.RSVP === "No" ? (
                    <CloseIcon />
                  ) : (
                    <HelpOutlineIcon />
                  )}
                </StyledTableCell>
                <StyledTableCell>{menuChoiceMap[row.meal_choice_starter]}</StyledTableCell>
                <StyledTableCell>{menuChoiceMap[row.meal_choice_main]}</StyledTableCell>
                <StyledTableCell>{menuChoiceMap[row.meal_choice_dessert]}</StyledTableCell>
                <StyledTableCell>{row.hotel_other !== null? row.hotel_other : hotelMap[row.hotel]}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table> : 
        <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <StyledTableHeaderCell />
            <StyledTableHeaderCell>Name</StyledTableHeaderCell>
            <StyledTableHeaderCell>Email</StyledTableHeaderCell>
            <StyledTableHeaderCell>Attending</StyledTableHeaderCell>
            <StyledTableHeaderCell>Hotel</StyledTableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.personData.map((row) => (
            <TableRow>
              <StyledTableCell>
                <IconButton
                  color="inherit"
                  aria-label="Edit"
                  component="span"
                  onClick={() => onClick(row.id)}
                >
                  <EditIcon />
                </IconButton>
                <ProfileModal setModalShow={setModalShow} setUpdatingData={props.setUpdatingData} modalShow={modalShow} openModal={openModal} onHide ={()=> setModalShow(false)} data={row} mealChoiceData={props.mealChoiceData} hotelData={props.hotelData}/>
              </StyledTableCell>
              <StyledTableCell>{row.first_name + " " + row.last_name}</StyledTableCell>
              <StyledTableCell>{row.email}</StyledTableCell>
              <StyledTableCell>
                {row.RSVP === "Yes" ? (
                  <CheckIcon />
                ) : row.RSVP === "No" ? (
                  <CloseIcon />
                ) : (
                  <HelpOutlineIcon />
                )}
              </StyledTableCell>
              <StyledTableCell>{row.hotel_other !== null? row.hotel_other : hotelMap[row.hotel]}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      }
      </TableContainer>
      <div className={"infoContainer"}>
        <div className={"information"}>
          <div className={"info-label"}>For more information: </div> 

          {showFood && <div><FontAwesomeIcon className={"info-icon"} icon={faUtensils} size="1x" /><HashLink to="/#food" className={"infoText"}>Food on the Day </HashLink>
          </div>}<div><FontAwesomeIcon className={"info-icon"} icon={faBed} size="1x" /><HashLink to="/#hotels" className={"infoText"}>Where to Stay</HashLink></div>
        </div>
      </div>
      </div>

    </div>
  );
}
export default Profile;
