import React, {useState} from 'react';
import Modal from '@material-ui/core/Modal';
import "./GiftPayModal.css"
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import PaymentDetailsModal from '../PaymentDetailsModal/PaymentDetailsModal';


function GiftPayModal(props) {
  const [paymentDetailsModalShow, setPaymentDetailsModalShow] =useState(false);
  const onClickNow = () => {
    setPaymentDetailsModalShow(true);
    props.onHide()
  }
  const onClickLater = () => {
    props.onHide()
  }
  const body = (
    <div className={"payBorder"}>
      <div className={"giftPayText"}>Thank you very much for the gift pledge. Please choose one of the following options:</div>
      <div style={{display: "flex", alignSelf: "center", justifyContent: "space-between"}}>
        <Button className={"giftPayButton"} onClick={() =>  onClickNow() }>Send Gift Now <FontAwesomeIcon style={{paddingLeft: "10px"}} icon={faCreditCard} size='lg'/></Button>
        <Button className={"giftPayButton"} onClick={() =>  onClickLater()}><div>Send Gift Later<div> </div>(click <FontAwesomeIcon style={{paddingLeft: "5px", paddingRight: "5px"}} icon={faCreditCard} size='lg'/> at any time)</div></Button>
        </div>
    </div>
    );
  return (
    <>
      <Modal
      open={props.show}
      onClose={(_, reason) => {reason !== "backdropClick" && onClickLater() && onClickNow()}}
      >
      {body}
      </Modal>
      <PaymentDetailsModal show={paymentDetailsModalShow} onHide={() => setPaymentDetailsModalShow(false)}/>
    </>
  )
}
export default GiftPayModal;