import "./Countdown.css";
import React, { useEffect, useState } from "react";
import calculateTimeLeft from "../../Functions/calculateTimeLeft";
import ReactFitText from "react-fittext";
function Countdown() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });
  const timerComponents = [];

  Object.keys(timeLeft).forEach(interval => {
    timerComponents.push(
      <div>
        <div className={"numbers white bold header-font"}>
          {timeLeft[interval] ? timeLeft[interval] : 0}
        </div>
        <div className={"header-font white countdown-days"}>{interval}</div>
      </div>
    );
  });
  return (
    <ReactFitText compressor={2}>
      <div className={"countdown"}>
        {timerComponents.length ? timerComponents : <span>Time's up!</span>}
      </div>
    </ReactFitText>
  );
}

export default Countdown;
