import React, {useState, useEffect} from "react";
import "../../App.css";
import { useForm } from "react-hook-form";
import "./LoginModal.css";
import _ from "lodash/fp";
import Modal from '@material-ui/core/Modal';
import ForgottenPasswordModal from "../ForgottenPasswordModal/ForgottenPasswordModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function LogInModal(props) {
  const { register, handleSubmit, errors } = useForm();
  const [forgottenPasswordModalShow, setForgottenPasswordModalShow] = useState(false)

 const onSubmit = async (data) => {
    await props.handleLogin(data);
  };
  useEffect(() => {
    if(props.loggedIn === true) {
      props.setShowLoginModal(false);
      props.setLoginError(undefined);
    }
  }, [props.loggedIn])

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  
  const body = (
    <div className={"formBorder"}>
      <form onSubmit={handleSubmit(onSubmit)} className={"logInForm"}>
        <a className={"close-icon"} onClick={() => props.onHide()}><FontAwesomeIcon icon={faTimes} size="2x"/></a>
        <h1 className={"modalHeader"}>Log In</h1>
        {props.loginError !== undefined && <p className={"error"}>Incorrect Username or Password</p> }
        <label className={"modalLabel"}>Username</label>
        <input
          className={"modal-input"}
          name="username"
          ref={register({
            required: "Required",
          })}
        />
        {_.get("username.type", errors) === "required" && (
          <p className={"error"}>This field is required</p>
        )}
        <label className={"modalLabel"}>Password</label>
        <div className="pass-wrapper"> 
          <input
            className={"modal-input"}
            id="password"
            name="password"
            ref={register({
              required: "required",
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.,])[A-Za-z\d@$!%*?&.,]{8,}$/i,
            })}
            type={passwordShown ? "text" : "password"}
          />
          <i onClick={togglePasswordVisiblity}><FontAwesomeIcon icon={passwordShown ? faEye : faEyeSlash} /></i>
        </div>
        {_.get("password.type", errors) === "pattern" && (
          <p className={"error"}>
            Password must contain 8+ characters, contatin at least one capital
            cetter, number and special character
          </p>
        )}
        <input className={"modal-input"} type="submit" />
        <a className={"login-link"} onClick={() => setForgottenPasswordModalShow(true)}>Forgot Password?</a>
        <ForgottenPasswordModal show={forgottenPasswordModalShow} onHide={() => setForgottenPasswordModalShow(false)}/>
      </form>
    </div>
  )

  return (
    <Modal
      className={"modal"}
      open={props.show}
      onClose={props.onHide}
    >
      {body}
    </Modal>
  );
}
export default LogInModal;
