import React, {useState} from 'react';
import "./Food.css";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import FoodItem from '../FoodItem/FoodItem';
function Food(props) {
    const [tabSelected, setTabSelected] = useState(1);
    
    var data = [];
    switch (tabSelected) {
        case 1:
            data=props.mealChoiceData.filter(choice => choice.course ==="Canape");
            break;
        case 2:
            data=props.mealChoiceData.filter(choice => choice.course ==="Starter");
            break;
        case 3:
            data=props.mealChoiceData.filter(choice => choice.course ==="Main");
            break;
        case 4:
            data=props.mealChoiceData.filter(choice => choice.course ==="Dessert");
            break;
    }
    const getAllergens = (choice) => {
        var allergyString =  ""
        choice.allergens.forEach(allergy => {
            if (choice.allergens[choice.allergens.length-1] === allergy) {
                allergyString += allergy.name   
            } else if (choice.allergens[choice.allergens.length-2] === allergy) {
                allergyString += allergy.name + " & "
            } else {
                allergyString += allergy.name + ", "
            }
        }) 
        return allergyString;
    }
    return(
        <div className={"pageWrapper"}>
            <div className={"food-section"} id={"food"}>
                <p className={"header-text"}>Food</p>
                <div className="day-content">
                <div className={"tab-bar"}>
                    <div
                    className={tabSelected === 1 ? "tab-selected" : "tab"}
                    onClick={() => setTabSelected(1)}
                    >
                    Canapés
                    </div>
                    <div
                    className={tabSelected === 2 ? "tab-selected" : "tab"}
                    onClick={() => setTabSelected(2)}
                    >
                    Starter
                    </div>
                    <div
                    className={tabSelected === 3 ? "tab-selected" : "tab"}
                    onClick={() => setTabSelected(3)}
                    >
                    Main
                    </div>
                    <div
                    className={tabSelected === 4 ? "tab-selected" : "tab"}
                    onClick={() => setTabSelected(4)}
                    >
                    Dessert
                    </div>
                </div>
                {data.map(choice => { 
                    return <FoodItem allergens={getAllergens(choice)} choice={choice}/>
                })}
                </div>
                <div className={"allergy-text"}> Please let us know if you have any dietary requirements as meals can be adapted (<a className={"allergy-text email-link"} href={"mailto: dan-and-emilys-wedding@outlook.com"} target="_blank">dan-and-emilys-wedding@outlook.com</a>)</div>
            </div>
        </div>
    );

}
export default Food;