import React from "react";
import Modal from '@material-ui/core/Modal';
import { useForm } from "react-hook-form";
import _ from "lodash/fp";
import "./ForgottenPasswordModal.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";


function ForgottenPasswordModal(props) {
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data) => {
        const mailto =
        "mailto:dan-and-emilys-wedding@outlook.com?subject=Forgotten Username or Password for " + data.fullName + "&body=Hi Emily and Dan, %0D%0A%0D%0APlease could you re-send me my username and password for your incredible website. %0D%0A%0D%0AMany Thanks, %0D%0A%0D%0A" + data.fullName;
         window.location.href = mailto;

      };
    return(
        <Modal
            open={props.show}
            onClose={props.onHide}
        >
            <div className={"passwordModal"}>
            <form onSubmit={handleSubmit(onSubmit)} className={"forgottenPasswordForm"}>
            <a className={"close-icon"} onClick={() => props.onHide()}><FontAwesomeIcon icon={faTimes} size="lg" /></a>
                
            <h1 className={"passwordModalHeader"}>Forgotten Username or Password</h1>
            <div style={{margin: "auto"}}>
            <label className={"modalLabel passwordLabel"}>Full Name</label>
            <input
            className={"passwordInput"}
            name="fullName"
             ref={register({
                required: "Required",
            })}
            />
            {_.get("fullName.type", errors) === "required" && (
                <p className={"error passwordError"}>This field is required</p>
            )}
            <input className={"passwordSubmit"} type="submit" />
            </div>
            </form>
            </div>
        </Modal>
    )
}
export default ForgottenPasswordModal;