import "./GettingThere.css";
import React from "react";

const GettingThere = () => {

    return (
    <div className={"pageWrapper"}>
      <div className={"directions-section"} id={"gettingThere"}>
        <p className={"header-text"}>Getting There</p>
        <div className={"mapContainer"}>
          <div className={"map"}>
          <iframe 
            src="https://www.google.com/maps/d/u/0/embed?mid=1b8Go731afzd_XDUXA7kmCqF29tfoU_Kj" 
            width="100%"
            height="100%"
            >
          </iframe>
          </div>
          <div className={"mapInfoContainer getting-there-text"}>
            <p>Guests will meet at St Mary's Church. Click on the church icon on the map to get Google Maps directions. </p>
            <p>Parking is limited at the church. We are looking into getting further parking and will update you nearer the time. You can share an email to contact you on via the profile portal (top right of the navigation bar once signed in) </p>
            <p>There is ample parking at Kingscote Barn, however vehicles must be collected 10am the day after the wedding.</p>
            <p>Unfortunately, due to the rural location, public transport will not be available. Please let us know if you will need assistance getting around on the day.</p>
            <p>Taxis to or from the venue must be booked in advance! Here are plenty of local options: <a className={"taxis-link"} href={"https://kingscotebarn.co.uk/local-taxis"} target="_blank">Taxis Here!</a></p>
            <p>Once you have booked accomodation please share this with us via the profile portal as this will help us match you with other guests who you may want to share taxis with etc.</p>
          </div>
        </div>
      </div>
      </div>
    )
}

export default GettingThere


