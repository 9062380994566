import React, {useState} from "react";
import { faLeaf } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';

function FoodItem(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleOnClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleOnClose = () => {
        setAnchorEl(null);
    }
    const open = Boolean(anchorEl);
    return (
        <div>
            {props.choice.childs_option?
                <p className={"choice-title"}>Little Option&nbsp;&nbsp;
                    {props.choice.allergens.length !== 0 && 
                    <>
                        <span onClick={handleOnClick}>
                            <FontAwesomeIcon icon={faLeaf} size="1x" />
                        </span>
                        <Popover 
                            open={open} 
                            onClose={handleOnClose}
                            anchorEl={anchorEl} 
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            {props.allergens}
                        </Popover>
                    </>
                    }               
                </p>
            :
            <p className={"choice-title"}>{props.choice.name}&nbsp;&nbsp;
            {props.choice.allergens.length !== 0 && 
            <>
                <span onClick={handleOnClick}>
                    <FontAwesomeIcon icon={faLeaf} size="1x" />
                </span>
                <Popover 
                    open={open} 
                    onClose={handleOnClose}
                    anchorEl={anchorEl} 
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {props.allergens}
                </Popover>
            </>
            }               
        </p>
            }
            {props.choice.course !== "Canape" && <p>{props.choice.info}</p>}
        </div>
    )

}

export default FoodItem;