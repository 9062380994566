import "./ImageLibrary.css";
import React, {useState, useCallback} from "react";
import Gallery from "react-photo-gallery";
import { photos, highResPhotos } from "./photos";
import Carousel from "../Carousel/Carousel";

const ImageLibrary = () => {
  
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setViewerIsOpen(false);
  };

  const onPrev = () => {
    let index = currentImage-1
    if (index < 0) {
        index = photos.length-1
    }
    setCurrentImage(index);
}

const onNext = () => {
    let index = currentImage+1
    if (index === photos.length) {
        index = 0
    } 
    setCurrentImage(index);
}

    return (
    <div className={"registry"}>
      <div className={"registry-section"} id={"imageLibrary"}>
        <p className={"header-text registry-title-text"}>Pictures</p> 
        {photos.length != 0 ? (
          <>
            <Gallery photos={photos} onClick={openLightbox} />
            <Carousel isOpen={viewerIsOpen} photos={photos} onClose={closeLightbox} currentImage={currentImage} highResPhotos={highResPhotos} onPrev={onPrev} onNext={onNext}/> 
          </>
        ) : <div className={"coming-soon-text"}> Pictures will be uploaded following the big day</div>}
        
      </div>
    </div>
    )
}

export default ImageLibrary


