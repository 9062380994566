import React from "react";
import party from "../../Assets/party.jpg";
import "./Party.css";
import { faMapMarkerAlt, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Party(props) {
  return (
    <div className={"party-section"}>
      <img alt={"party"} src={party} className={"party-image"} width="350" />
      <div className={"party-content"}>
        <div className="party-when-where">
          <p className="party-when-where-text"> When and Where</p>
          <div className={"party-where"}>
            <p className={"where-icon"}>
              <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
            </p>

            <p className="party-where-text">
              The Kingscote Barn, Binley Farm, Kingscote, Tetbury GL8 8YE
            </p>
          </div>
          <div className={"party-when"}>
          {props.loggedIn && <><p className={"when-icon"}>
              <FontAwesomeIcon icon={faClock} size="lg" />
            </p>

            <p className="party-when-text">19:30 - 00:00, August 15, 2022</p></>}
          </div>
        </div>
      </div>
    </div>
  );
}
